<template>
  <v-card
    :id="`offer-${offer.id}`"
    :style="baseStyles"
    :data-title="offerType"
    class="cw-offer-price-card"
  >
    <v-card-text
      :class="{ 'white--text': active }"
      class="cw-offer-price-card__content text-center px-6 mt-8"
    >
      <component
        :is="logo"
        class="cw-offer-price-card__logo my-8"
      />

      <p class="text-h4 mb-8">
        <template v-if="offer.type !== 'continuousLoan'">
          <div
            v-translate="{
              instalmentAmount: $filters.currency(offer.instalmentAmount, { locale }),
            }"
          >
            %{ instalmentAmount } / month
          </div>
        </template>
        <template v-else>
          {{ offer.loanLimit | currency({ locale }) }}
        </template>
      </p>

      <div
        v-for="row in offerRows"
        :key="row.key"
        class="align-center justify-space-between d-flex"
      >
        <p class="text-body-2 pl-2 mb-1 font-weight-thin">
          {{ row.title }}
        </p>
        <p class="text-body-2 pr-2 mb-1 font-weight-bold">
          {{ row.filter ? $filters[row.filter](row.value, row.filterOptions || {}) : row.value }}
          {{ row.suffix(row.value) }}
        </p>
      </div>
    </v-card-text>

    <v-card-actions class="pa-6">
      <v-btn
        :id="`select-offer--${offer.id}`"
        :class="{ 'elevation-0': active }"
        :color="active ? 'transparent' : colors.secondary"
        :text="active"
        class="offerSelectionButton"
        block
        dark
        depressed
        @click="
          setSelectedOfferId(offer.id);
          $eventLogger.clickEvent($event);
        "
      >
        <v-icon v-if="active">
          check
        </v-icon>
        <template v-else>
          <translate>
            Choose
          </translate>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { programs } from '@shared/config';
import getDynamicProperty from '@shared/utils/getDynamicProperty';

export default {
  name: 'CwLoanOfferPriceCard',

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    index: {
      type: Number,
      default: 0,
    },

    isBusinessLoan: {
      type: Boolean,
      default: false,
    },

    offer: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      locale: 'application/locale',
      product: 'application/getProduct',
    }),

    baseStyles() {
      return {
        backgroundColor: this.active ? this.colors.primary : 'white',
      };
    },

    brand() {
      if (this.offer.loanProgramName !== undefined) {
        return this.offer.loanProgramName.toLowerCase().split('.')[0];
      }

      return this.product.productName.toLowerCase().split('.')[0];
    },

    colors() {
      if (!programs || !this.brand) return {};

      if (this.shouldChangeToSaldo) {
        return programs.saldo.colors;
      }

      return programs[this.brand].colors;
    },

    logo() {
      if (!this.logoFilename) return '';

      return () => import('@shared/assets/images/logos/' + this.logoFilename); // eslint-disable-line
    },

    logoFilename() {
      if (!this.brand || !this.offer) return '';

      const variant = this.active ? '-negative' : '';
      const filename = `${this.brand.toLowerCase()}${variant}.svg`;

      if (this.shouldChangeToSaldo) {
        return `saldo${variant}.svg`;
      }
      return filename;
    },

    offerRows() {
      const {
        offer,
        isBusinessLoan,
        $gettext,
        $ngettext,
      } = this;

      const items = [{
        title: $gettext('Loan amount'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'loanLimit',
        suffix: () => '',
      }];

      const continuousLoanItems = [{
        title: $gettext('The loan period'),
        filter: null,
        key: 'instalmentType',
        value: $gettext('Flexible'),
        suffix: () => '',
      }];

      const instalmentLoanItems = [{
        title: $gettext('The loan period'),
        filter: null,
        key: 'numberOfInstalments',
        suffix: value => $ngettext('mo', 'mos', value),
      }];

      const businessLoanItems = [{
        title: $gettext('Monthly Instalment'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'instalmentAmount',
        suffix: () => '',
      }, {
        title: $gettext('Monthly interest rate'),
        filter: 'percent',
        key: 'monthlyInterestRate',
        suffix: () => '',
      }, {
        title: $gettext('Opening fee'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'deliveryFeeTotal',
        suffix: () => '',
      }, {
        title: $gettext('Account management fee'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'maintenanceFee',
        suffix: () => '',
      }, {
        title: $gettext('To be repaid'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'instalmentTotals.instalmentAmount',
        suffix: () => '',
      }];

      if (offer.type === 'instalmentLoan') items.push(...instalmentLoanItems);
      if (isBusinessLoan) items.push(...businessLoanItems);

      // add values and return rows
      const rows = items.map(item => ({ ...item, value: getDynamicProperty(offer, item.key) }));

      if (offer.type === 'continuousLoan') rows.push(...continuousLoanItems);

      return rows;
    },

    offerType() {
      const { brand, isBusinessLoan, offer } = this;
      if (!programs || !brand || !offer) return '';

      if (isBusinessLoan) return this.$gettext('Corporate loan');

      if (this.shouldChangeToSaldo) {
        return programs.saldo[offer.type].type;
      }

      return programs[brand][offer.type].type;
    },

    shouldChangeToSaldo() {
      return this.brand === 'vippi' && this.offer.type.startsWith('brokered');
    },
  },

  methods: {
    setSelectedOfferId(id) {
      this.$bus.$emit('SELECTED_OFFER_ID__SET', id);
    },
  },
};
</script>

<style lang="scss">
.cw-offer-price-card {
  border-radius: 6px;
  margin: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &::after {
    content: attr(data-title);
    position: absolute;
    border-radius: 0;
    right: auto;
    left: -8px;
    top: 16px;
    transform: rotate(0deg);
    color: #fff;
    padding: 0 24px 0 16px;
    height: 36px;
    display: grid;
    place-content: center;
    font-weight: 400;
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    background-color: #7a37d2;
  }
  // ribbon fold
  &::before {
    content: "";
    position: absolute;
    top: 23px;
    transform: skewY(40deg);
    left: -8px;
    width: 8px;
    height: 32px;
    background-color: #4b2258;
  }

  &__content {
    flex: 1 0 auto;
  }

  &__logo {
    max-height: 26px;
    max-width: 100%;
  }

  &.v-card {
    @include mq($from: sm) {
      max-width: 320px;
    }
  }
}
</style>
